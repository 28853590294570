import { ChevronDown } from "lucide-react";
import { marked } from "marked";
import { useEffect, useState } from "react";

const faqItems = [
    {
        question: "What does a tailored resume mean?",
        answer: "A tailored resume is a document that has been customized to match the specific requirements of a job posting. Instead of using a generic one-size-fits-all resume, tailoring means **highlighting the skills and experiences most relevant to the position** you're applying for. This process shows employers that you understand the role and have the exact qualifications they're looking for. With TailoredCV, we use AI to analyze both your experience and the job description to create a perfectly matched resume that increases your chances of getting an interview.",
    },
    {
        question: "Why should I use TailoredCV to tailor my resume?",
        answer: "At TailoredCV, we've developed an **AI-powered system that analyzes job descriptions and your existing resume to create perfectly tailored versions in minutes**. Our AI understands the key requirements of each job posting and restructures your resume to emphasize your most relevant skills and experiences. **This process, which could take hours if done manually, is completed in just a few clicks**. Our tool also ensures your resume is ATS-friendly, meaning it will pass through applicant tracking systems that many employers use to filter candidates. By using TailoredCV, you save time while creating more effective, targeted job applications.",
    },
    {
        question: "Are TailoredCV resumes ATS-friendly?",
        answer: "Yes, all resumes created with TailoredCV are specifically designed to be ATS-friendly. Our AI technology understands how Applicant Tracking Systems (ATS) work and optimizes your resume accordingly. We ensure your tailored resume includes the right keywords from the job description while maintaining a clean, scannable format that ATS software can easily process. This significantly increases the chances of your resume making it past the initial screening and into the hands of actual hiring managers.",
    },
    {
        question: "Can I edit my resume after it's been tailored?",
        answer: "Absolutely! While our AI does an excellent job of tailoring your resume to match job descriptions, we understand you might want to make personal adjustments. After the AI generates your tailored resume, you can edit any section as needed. Our intuitive editor allows you to fine-tune the wording, add additional information, or adjust formatting to your preference. This gives you the perfect balance of AI efficiency and personal touch in your job applications.",
    },
    {
        question: "How do I tailor my resume with no experience?",
        answer: `Even with limited work experience, you can create an effective tailored resume with TailoredCV. Our system helps you:

1. **Highlight transferable skills** from academic projects, volunteer work, or internships that match the job requirements
2. **Emphasize relevant coursework or certifications** that demonstrate your knowledge in the field
3. **Showcase soft skills** like communication, problem-solving, or teamwork that are mentioned in the job description
4. **Format your resume professionally** to make the most of the experience you do have

Remember, everyone starts somewhere! TailoredCV helps you present yourself in the best possible light regardless of your experience level.`,
    },
    {
        question: "How does TailoredCV generate resume options?",
        answer: `We offer three levels of customization for each section of your resume:

1. **Conservative:** This option uses synonyms and mild tone changes to subtly tailor your resume while keeping most of your original content intact.
2. **Balanced:** This option is more creative, adding relevant points based on your experiences to better match the job description.
3. **Creative:** This option gives our AI the most freedom to restructure and enhance your resume, potentially adding new relevant skills or experiences based on your profile.

You can **mix and match these options for different sections of your resume, or make manual tweaks** as you see fit. This flexibility ensures you have full control over your final resume while benefiting from our AI's insights.`,
    },
    {
        question: "Is my information secure with TailoredCV?",
        answer: "We take your privacy and security very seriously. **Your resume data is stored securely on our encrypted servers, and we never share your personal information**. Our AI uses your data solely for the purpose of tailoring your resume. We comply with all relevant data protection regulations to ensure your information is always safe with us.",
    },
    {
        question: "What if I need help using TailoredCV?",
        answer: "We're here to support you every step of the way! We offer **comprehensive guides and tutorials within the platform** to help you make the most of our features. If you need additional assistance, our **dedicated support team is always ready to help**. You can reach out to us via email or through our in-app chat support, and we'll be happy to address any questions or concerns you may have.",
    },
    {
        question: "How much does TailoredCV cost?",
        answer: `We offer a Pro Plan with two flexible pricing options:

1. **$10 per month** when paid annually (that's just $120 for a full year!)
2. **$12 per month** when paid monthly

Both options give you full access to all our features, including AI-powered resume customization, unlimited resume tailoring, resume performance analytics, and multiple resume templates.

Considering the potential impact on your job search and career, TailoredCV offers **exceptional value for money**. Plus, we often run special promotions, so keep an eye out for even better deals!`,
    },
];

export default function FAQComponent({
    faqs = faqItems,
}: { faqs?: { question: string; answer: string }[] }) {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    useEffect(() => {
        marked.use({
            breaks: true,
            gfm: true,
        });
    }, []);

    return (
        <section className="w-full px-4 py-12" id="faq">
            <div className="max-w-4xl mx-auto">
                <h2 className="bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 bg-clip-text pb-4 text-center font-bold text-4xl text-transparent md:text-5xl mb-8">
                    Frequently Asked Questions
                </h2>
                <div className="space-y-4">
                    {faqs.map((item, index) => (
                        <div
                            key={item.question}
                            className="border rounded-lg overflow-hidden shadow-sm"
                        >
                            <button
                                className="flex justify-between items-center w-full p-5 text-left bg-white hover:bg-gray-50 transition-colors duration-200 plausible-event-name=FAQ"
                                onClick={() => setOpenIndex(openIndex === index ? null : index)}
                            >
                                <span className="font-semibold text-black">{item.question}</span>
                                <ChevronDown
                                    className={`w-5 h-5 text-gray-500 transition-transform duration-200 ${openIndex === index ? "rotate-180" : ""}`}
                                />
                            </button>
                            <div
                                className={`overflow-hidden transition-all duration-300 ease-in-out ${openIndex === index ? "max-h-[1200px]" : "max-h-0"}`}
                            >
                                <div
                                    className="p-5 text-gray-600 bg-white prose prose-sm max-w-none"
                                    // biome-ignore lint/security/noDangerouslySetInnerHtml: marked is a safe library, and the content is created by us
                                    dangerouslySetInnerHTML={{ __html: marked(item.answer) }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}
